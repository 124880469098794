import React, { useState, useEffect } from "react";
import {Container,Row, Col, Form, Button, InputGroup, Modal,} from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "./navbar.css";
import CustomNavbar from "./navbar";
import logo from '../../logo.svg';
import { FaEllipsisV } from "react-icons/fa"; 

const Home = () => {
    const [urlInput, setUrlInput] = useState("");
    const [shortenedUrls, setShortenedUrls] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [showOptions, setShowOptions] = useState(false);  // Manage options visibility
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedUrl, setSelectedUrl] = useState(null); // Store selected URL for editing or deleting
  
  
    // Fetch stored URLs on mount
    useEffect(() => {
      const fetchUrls = async () => {
        const storedUrls = await AsyncStorage.getItem("storedUrls");
        if (storedUrls) {
          setShortenedUrls(JSON.parse(storedUrls));
        }
      };
      fetchUrls();
    }, []);
  
    const handleShortenUrl = async () => {
        if (!urlInput) {
          setTooltipMessage("Please enter a URL.");
          setShowTooltip(true);
          return;
        }
    
        if (!urlInput.startsWith("https:")) {
          setTooltipMessage("Please enter a valid URL starting with https:");
          setShowTooltip(true);
          return;
        }
    
        // Clear any tooltip and validation errors
        setShowTooltip(false);
        setShowPopup(true)
        // Mock shortened URL generation
        const newUrl = `${process.env.REACT_APP_BASE_URL}/${Math.random().toString(36).substr(2, 8)}`;
        setGeneratedUrl(newUrl)
        const newEntry = { original: urlInput, short: newUrl };
        const updatedUrls = [...shortenedUrls, newEntry];
        await AsyncStorage.setItem("storedUrls", JSON.stringify(updatedUrls));
       
        setShortenedUrls(updatedUrls);
        setGeneratedUrl(newUrl);
          
      
      };
    
    
      const handleClosePopup = () => setShowPopup(false);
  
      const handleCopyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(generatedUrl);
          alert("Shortened URL copied to clipboard!");
        } catch (error) {
          console.error("Error copying to clipboard:", error);
        }
      };
      const handleDelete = async (index) => {
        const updatedUrls = shortenedUrls.filter((_, i) => i !== index);
        setShortenedUrls(updatedUrls);
        await AsyncStorage.setItem("storedUrls", JSON.stringify(updatedUrls));
      };
     
      const handleOptionsToggle = (index) => {
        if (editingIndex === index) {
          setShowOptions(!showOptions);  // Toggle if the same item is clicked again
        } else {
          setEditingIndex(index);
          setShowOptions(true); // Show options if a new item is clicked
        }
        setSelectedUrl(shortenedUrls[index]);
      };
    
  return (
    <Container fluid className="p-0">
     <CustomNavbar/>
        <div style = {{marginTop:"150PX"}}>
        <h1 className="mb-3" style = {{marginTop:"50px"}}>URL shortener</h1>
        <p className="mb-4">
          Short link generator to create short trackable links
        </p>
        </div>
        {/* <Button variant="dark" className="cta-button">
          Create free account →
        </Button> */}
        <Col xs={12} md={{ span: 6, offset: 3 }} className="shortened-url-section">
            <InputGroup className="mb-3">
            <Form.Control
            type="text"
            placeholder="Enter your URL here..."
            className="url-input"
            value={urlInput}
            isInvalid={showTooltip} // Highlight input field on error
            onChange={(e) => {
                setUrlInput(e.target.value);
                setShowTooltip(false); // Hide tooltip on input change
            }}
            />
                <Button variant="dark" className="shorten-button" onClick={handleShortenUrl}>
                    Shorten URL
                </Button>
            <Form.Control.Feedback type="invalid" tooltip>
            {tooltipMessage}
            </Form.Control.Feedback>
            </InputGroup>
            <h3 className="mt-4">Stored URLs</h3>
            {shortenedUrls.length > 0 ? (
                <ul className="list-group">
                {shortenedUrls.map((entry, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div style={{display:"flex",}}>
                        <div>
                            <img 
                            src={logo}
                            alt="Logo"
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            />
                        </div>
                        <div style={{alignContent:"flex-start",alignItems:"flex-start",display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
                        <a href={entry.original} target="_blank" rel="noopener noreferrer" style={{ marginBottom: -25,color:"#0040ff",textDecoration:"underline" }}>{entry.short}</a><br />
                         <a href={entry.original} target="_blank" rel="noopener noreferrer" style = {{color:"gray",fontWeight:"normal"}}>{entry.original}</a>
                         </div>
                    </div>
                    <Button
                    variant="outline-white"
                    size="sm"
                    onClick={() => handleOptionsToggle(index)}  // Trigger options toggle
                  >
                    <FaEllipsisV style={{ cursor: "pointer", marginLeft: "10px" }} />
                  </Button>
                  {showOptions && editingIndex === index && ( // Show options only for the clicked item
                    <div
                    className="options-popup"
                    style={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        zIndex: 10,
                        alignContent:"center",
                        alignItems:"center"
                    }}
                    >
                    <Button
                         variant="outline-danger"
                        onClick={() => {
                        handleDelete(index);
                        setShowOptions(false); // Close the menu after action
                        }}
                        style={{ display: "block", textAlign: "center", padding: "5px 10px" }}
                    >
                        Delete
                    </Button>
                    </div>
                    )}
        
                    </li>
                ))}
                </ul>
            ) : (
                <p className="text-muted">No URLs have been shortened yet.</p>
            )}
        </Col>

        {/* </Row> */}



            <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>Short Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-success">✅ Link shortened successfully!</p>
          <InputGroup>
            <Form.Control
              type="text"
              readOnly
              value={generatedUrl}
              className="form-control"
            />
            <Button
              variant="outline-secondary"
              onClick={() => navigator.clipboard.writeText(generatedUrl)}
            >
              Copy
            </Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Home;
