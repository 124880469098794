import React from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import logo from '../../logo.svg';
import './navbar.css';

const CustomNavbar = () => {
  return (
    <Navbar bg="light" expand="lg"  className="px-4 fixed-top">
      <Navbar.Brand href="#" className="d-flex align-items-center">
        <img 
          src={logo}
          alt="Logo"
          width="100"
          height="50"
          className="d-inline-block align-top"
        />
        {/* <h1 className="ms-2">ibm</h1> */}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          {/* <NavDropdown title="Products" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Product 1</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Product 2</NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="#pricing">Pricing</Nav.Link>
          <Nav.Link href="#documentation">Documentation</Nav.Link>
          <Nav.Link href="#api">API</Nav.Link>
          <Nav.Link href="#contact">Contact us</Nav.Link> */}
        </Nav>
        <div>
          <Nav className="ms-auto">
            <Button variant="outline-dark" className="me-2 mb-2">
              Log in
            </Button>
            <Button variant="dark" className="me-2 mb-2">Sign up</Button>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
